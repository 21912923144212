<template>
  <div class="min-h-screen">
    <div class="centerDiv">
      <img src="../assets/companylogo.png" alt="Company Logo" width="200" height="150" class="mb-4" style="border: 1px solid lightgray;"/>
      <form @submit.prevent="login">
        <p class="error" v-show="error">{{ error }}</p>
        <div class="p-inputgroup my-4">
          <span class="p-inputgroup-addon">
            <i class="pi pi-user"></i>
          </span>
          <span class="p-float-label">
            <p-input-text id="username" type="text" v-model="username" autofocus />
            <label for="username">Username</label>
          </span>
        </div>
        <div class="p-inputgroup my-4">
          <span class="p-inputgroup-addon">
            <i class="pi pi-lock"></i>
          </span>
          <span class="p-float-label">
            <p-input-text id="password" type="password" v-model="password" />
            <label for="password">Password</label>
          </span>
        </div>
        <p-button type="submit" label="Sign In" class="w-full" :loading="isLoading" />
      </form>
      <div class="text-500 mt-5">Keymas Ltd &copy;</div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import axios from "axios"

export default {
  name: "LoginUserLayout",
  components: {
    "p-input-text" : InputText,
    "p-button" : Button
  },
  created (){
    let hostURL = window.location.host
    this.company = hostURL.split('.')[0]
  },
  data() {
    return {
      username: "",
      password: "",
      company: "",
      error: null,
      isLoading: false,
    }
  },
  methods: {
    login () {
      this.isLoading = true

      axios.post(window.baseUrl + "auth/login", { username: this.username, password: this.password, company: this.company })
          .then(response => {
            if(response.data.success){
              this.$store.dispatch("login", response.data)
              this.$router.push({ name: "Dashboard" })
            }
            else if (response.data.error) {
              this.error = response.data.error
            }
            else {
              this.error = "Invalid login credentials"
            }

            this.isLoading = false
          })
          .catch(error => {
            // Show network error if we cant login:
            this.$router.push({ name: "Network Error" })
            console.log("Network error logging in: " + error)
          })
    }
  },
}
</script>

<style scoped>
.centerDiv {
  max-width: 340px;
  margin: auto;
  align-items: center;
  text-align: center;
  padding-top: 10%;
}

.error {
  padding: 0.5em;
  background-color: indianred;
  color: whitesmoke;
  border-radius: 0.5em;
}
</style>