import { createRouter, createWebHistory } from "vue-router"
import LoginView from "../views/LoginView.vue"
import PermissionsService from "@/services/PermissionsService"

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/movement-logs",
    name: "Movement Logs",
    component: () =>
        import(/* webpackChunkName: "movement-logs" */ "../views/MovementLogsView"),
    meta: { pageCategory: "Movement Logs", requiresAuth: true }
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
        import(/* webpackChunkName: "movement-reports" */ "../views/ReportsView"),
    meta: { pageCategory: "Reports", requiresAuth: true },
    children: [
      {
        path: "",
        name: "Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-reports" */ "../components/reports/ReportsMenu"),
      },
      {
        path: "pallets-older-than-days",
        name: "Report Pallets Older Than Days",
        component: () =>
            import(/* webpackChunkName: "group-reports" */ "../components/reports/ReportsPalletsOlderThanDays"),
      }
    ]
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
        import(/* webpackChunkName: "users" */ "../views/UsersView.vue"),
    meta: { pageCategory: "Users", requiresAuth: true }
  },
  {
    path: "/:catchAll(.*)", // Match all routes that do not match an existing route
    name: "Not found",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/NotFoundErrorView")
  },
  {
    path: "/network-error",
    name: "Network Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/NetworkErrorView")
  },
  {
    path: "/token-error",
    name: "Token Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/JwtTokenErrorView")
  },
  {
    path: "/permission-error",
    name: "Permission Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/PermissionErrorView")
  },
  {
    path: "/api-error",
    name: "API Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/ApiErrorView")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  /* Save a users scroll position between pages */
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition;
    } else{
      return { top: 0 };
    }
  }
})

// Create a general route guard:
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user")

  // If the route requires authentication and user is not logged in:
  if (to.meta.requiresAuth && !loggedIn) {
    // Redirect to log in:
    next("/")
  }

  // If the route requires authentication and write access:
  else if (to.meta.requiresAuth && to.meta.requiresWriteAccess) {
    let pageView = PermissionsService.checkPageView(to.meta.pageCategory)
    let pageEdit = PermissionsService.checkPageEdit(to.meta.pageCategory)

    if (pageView && pageEdit) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If the route requires authentication and has a page category:
  else if (to.meta.requiresAuth && to.meta.pageCategory) {
    let pageView = PermissionsService.checkPageView(to.meta.pageCategory)

    if (pageView) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If a user is logged in and tries to go to the login route via the URL:
  else if (loggedIn && to.name === "Login") {
    next("dashboard")
  }

  else {
    // Continue to route:
    next()
  }

  document.title = to.name || "Kstore Pallet Track"
})


export default router;
