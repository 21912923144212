import store from "../store/index.js"

export default {
    checkPageView (page) {
        const pageRights = store.getters.userPageRights
        let pageView = false

        if (pageRights) {
            // For each item in page rights:
            pageRights.map(item => {
                // Page view is true if an item name matches a page, and it has access:
                if (item.name === page && item.page_view === "1") {
                    pageView = true
                }
            })
        }

        return pageView
    },

    checkPageEdit (page) {
        const pageRights = store.getters.userPageRights
        let pageEdit = false

        if (pageRights) {
            // For each item in page rights:
            pageRights.map(item => {
                // Page access is true if an item name matches a page, and it has access:
                if (item.name === page && item.page_edit === "1") {
                    pageEdit = true
                }
            })
        }

        return pageEdit
    },
}